/* Standard Eric Meyer reset */
/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
	display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
    display: none;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* Get the variables in early so they're available */
/*
  The breakpoints for all resolutions represented as pairs with the breakpoint name and its associated pixel width.
  @media (--medium) { ...styles... }
*/
/* Above-targt breakpoints */
/* Below-target breakpoints */
/* Motion reduction breakpoint */
/* High contrast breakpoints */
/*
  Allows you to create your own selectors
  http://cssnext.io/features/#custom-selectors
  .MyModule:--enter {
    ...
  }
*/
/*
  The z-indexes for the project.
  z-index: var(--zindex--nav);
*/
/* Import the utility classes */
.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
.color--text {
    color: rgba(51, 51, 51, 1);
  }
.color--action {
    color: #6e9e75;
  }
.color--primary-1 {
    color: #6e9e75;
  }
.color--primary-2 {
    color: #3f2500;
  }
.color--primary-3 {
    color: #837b67;
  }
.color--secondary-1 {
    color: #4ea9b0;
  }
.color--secondary-2 {
    color: #515139;
  }
.color--secondary-3 {
    color: #1a476e;
  }
.color--secondary-4 {
    color: #8b0c23;
  }
.color--sup-1 {
    color: var(--color-secondary-6);
  }
.color--sup-2 {
    color: var(--color-secondary-7);
  }
.color--sup-3 {
    color: var(--color-secondary-8);
  }
.color--sup-4 {
    color: var(--color-secondary-9);
  }
.color--sup-5 {
    color: var(--color-secondary-5);
  }
.color--sup-6 {
    color: var(--color-secondary-6);
  }
.color--sup-7 {
    color: var(--color-secondary-7);
  }
.color--sup-8 {
    color: var(--color-secondary-8);
  }
.color--white {
    color: #ffffff;
  }
.color--white .heading {
      color: #3f2500;
    }
.color--white .link:hover,.color--white .link:active,.color--white .link:focus {
        color: #ffffff;
      }
.color--gray-1 {
    color: #9e9e9e;
  }
.color--gray-2 {
    color: #c2c9d1;
  }
.color--gray-3 {
    color: #d8d8d8;
  }
.bg--primary-1 {
    background-color: #6e9e75 !important;
  }
.bg--primary-2 {
    background-color: #3f2500 !important;
  }
.bg--primary-3 {
    background-color: #837b67 !important;
  }
.bg--secondary-1 {
    background-color: #4ea9b0 !important;
  }
.bg--secondary-2 {
    background-color: #515139 !important;
  }
.bg--secondary-3 {
    background-color: #1a476e !important;
  }
.bg--secondary-4 {
    background-color: #8b0c23 !important;
  }
.bg--sup-1 {
    background-color: #b7dff6 !important;
  }
.bg--sup-2 {
    background-color: #0e95bc !important;
  }
.bg--sup-3 {
    background-color: #a2ba69 !important;
  }
.bg--sup-4 {
    background-color: #f7db6a !important;
  }
.bg--sup-5 {
    background-color: #f16a45 !important;
  }
.bg--sup-6 {
    background-color: #da3754 !important;
  }
.bg--sup-7 {
    background-color: #a8216b !important;
  }
.bg--sup-8 {
    background-color: #33797e !important;
  }
.bg--white {
    background-color: #ffffff !important;
  }
.bg--gray-1 {
    background-color: #9e9e9e !important;
  }
.bg--gray-2 {
    background-color: #c2c9d1 !important;
  }
.bg--gray-3 {
    background-color: #d8d8d8 !important;
  }
.bg--black {
    background-color: #000000 !important;
  }
.page-intro {
  background-color: #1a476e;
  color: #ffffff;
}
.page-intro__layout {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 58px auto;
    padding: 3rem 0;
  }
.page-intro__content {
    grid-column: 1/span 12;
    grid-row: 1;
  }
.page-intro__icon {
    display: none;
  }
.page-intro .heading {
    color: #ffffff;
  }
.page-intro .heading a {
      float: right; 
      font-size: 1rem !important;
    }
.page-intro p {
    max-width: 56rem;
  }
.page-intro a {
    color: #ffffff;
    display: inline-block;
    text-decoration: none;
  }
.page-intro a:hover,.page-intro a:active,.page-intro a:focus {
      text-decoration: underline;
    }
.notifications__header {
    margin-bottom: 1.5rem;
  }
.notifications__content {
    align-content: start;
    display: grid;
    font-size: .875rem;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
  }
.notification {
  border: solid 1px #ededed;
  border-radius: 5px;
  padding: .75rem;
}
.notification__title {
    color: inherit;
  }
.notification__message {
    /* comment */
  }
.notification__layout {
    display: grid;
    grid-template-columns: 2.5rem 1fr;
  }
.section-wrapper {
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 3rem auto;
  max-width: 75rem;
}
.form-section {
  border: solid 1px #efefef;
  border-radius: 5px;
  margin: 3rem 0;
  padding: 0 0 1.5rem 0;
}
.form-section__header {
    align-items: center;
    background-color: #efefef;
    display: flex; 
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: space-between;
    margin-bottom: 3rem;
    padding: 1.5rem;
  }
.form-section__content {
    padding: 0 1.5rem;
  }
.form-section__fields {
    margin-top: 3rem;
  }
.form-section__actions {
    margin-top: 1.5rem;
  }
.read-only, .field-wrapper {
  align-items: start;
  display: grid;
  font-size: .875rem;
  grid-gap: 1.5rem;
  margin: .75rem 0;
}
.read-only__title, .field-wrapper__title {
    font-weight: bold;
    text-transform: uppercase;
    padding-right: 1.5rem;
  }
.read-only + .read-only, .read-only + .field-wrapper, .field-wrapper + .read-only, .field-wrapper + .field-wrapper {
    border-top: solid 1px #ededed;
    padding-top: .75rem;
  }
.grants__content {
    align-items: stretch;
    display: grid;
    grid-gap: 1.5rem;
    padding-bottom: 3rem;
  }
.grants__list {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr;
  }
.grants__list .grant {
      text-align: center;
    }
.grant {
  border: solid 1px #ededed;
  border-radius: 5px;
  padding: 1.5rem;
  text-align: center;
}
.grant__cta {
    text-align: center; 
  }
.grant__content {
    flex: 1 1 auto;
    min-height: 8rem;
  }
.grant__layout {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100%;
  }
.pageshell__main {
  padding: 1.5rem 0;
}
.button.button--has-icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}
.button.button--has-icon svg {
  margin-right: 1ch;
}
.divider {
  border-bottom: 1px solid #ededed;
  margin: 3rem 0;
}
.save-edit-end {
  display: flex;
  justify-content: flex-end;
  padding: 3rem 0;
}
.required {
  color: #8b0c23;
}
.progress-bar {
  background-color: transparent;
  margin-top: 3rem;
}
.progress-bar__labels {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.progress-bar__label {
  display: flex;
  flex: 1 0 auto;
  padding: 0 0 0 1rem;
}
.progress-bar__label * + * {
  padding-left: 1ch;
}
/* MaterialUI overrides */
.MuiButtonBase-root:hover, .MuiButtonBase-root:active, .MuiButtonBase-root:focus {
  background-color: initial !important;
}
.css-15fev7r-MuiGrid-root {
  display: block !important;
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}
.css-o4b71y-MuiAccordionSummary-content {
  flex-direction: column;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  cursor: initial !important;
}
.css-nt71l3-MuiAutocomplete-root .MuiAutocomplete-inputRoot.MuiOutlinedInput-root, .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root, .css-15v22id-MuiAccordionDetails-root, .css-1qd6l8g, .css-1r50wcf-MuiButtonBase-root-MuiIconButton-root-MuiCheckbox-root {
  padding: 0 !important;
}
/* legend */
.css-28p64e-MuiFormLabel-root {
  color: rgba(51, 51, 51, 1) !important;
  font-weight: 700;
}
/* autocomplete thing */
.css-nt71l3-MuiAutocomplete-root {
  background-color: #ffffff !important;
  height: auto !important;
  padding: initial !important;
}
.css-emi4wi-MuiFormLabel-root-MuiInputLabel-root {
  font-size: .875rem;
  top: -7px !important;
}
/* checkboxes */
.css-4jkopv-MuiIconButton-label {
  margin-right: 1ch;
}
.css-j204z7-MuiFormControlLabel-root {
  margin: .25rem 0 .25rem 0 !important;
}
/* accordion */
.MuiAccordionSummary-root {
  background-color: #4ea9b0 !important;
  color: #ffffff !important;
  padding: .5rem .75rem !important;
  cursor: pointer !important;
}
.MuiAccordionSummary-root:hover,.MuiAccordionSummary-root:active,.MuiAccordionSummary-root:focus {
    background-color: #4ea9b0 !important;
  }
.applications-page .MuiAccordionSummary-root {
    background-color: #ffffff !important;
    color: rgba(51, 51, 51, 1) !important;
  }
.MuiAccordionDetails-root {
  padding: .5rem .75rem !important;
}
/* tabs */
.MuiTabs-flexContainer {
  flex-wrap: wrap !important;
}
[role="tablist"] [role="tab"].Mui-selected {
  background-color: #eeeeee;
  font-weight: bold;
}
.MuiTabs-indicator {
  background-color: transparent !important;
}
.css-t4qmgb-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}
.makeStyles-root-14 *, .makeStyles-root-19 * {
  border-radius: 5px !important;
}
.css-1exgqav-MuiGrid-root {
  grid-spacing: 1.5rem;
}
.css-1exgqav-MuiGrid-root > .MuiGrid-item {
  padding-left: 0 !important;
  padding-top: 0 !important;
}
.css-ahj2mt-MuiTypography-root {
  font-size: .875rem !important;
}
.css-o4b71y-MuiAccordionSummary-content, .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}
/* text inputs */
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: #ffffff !important;
  border: 1px solid #d8d8d8 !important;
  border-radius: 3px !important;
  color: rgba(51, 51, 51, 1) !important;
  font-family: 'Open Sans' !important;
  font-weight: 400 !important;
  font-size: .875rem !important;
  line-height: 1 !important;
  padding: 0.75rem !important;
}
.button + .button {
  margin-left: 1.5rem;
}
.form-wrapper {
  margin: 0 auto;
  max-width: 60rem;
}
.fieldrow {
  margin-bottom: 1.5rem;
}
.grant-action-buttons {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 3rem;
}
.input-field + .input-field {
  margin-top: 1.5rem;
  border-top: 1px solid #ededed;
  padding-top: 1.5rem;
}
.alert {
  margin: 3rem 0;
}
.accordion__details {
    border: 1px solid #ededed;
    padding: 1.5rem !important;
  }
.accordion__summary {
    background-color: #ededed !important;
    padding: .5rem 1.5rem !important;
  }
.accordion__summary:hover,.accordion__summary:active,.accordion__summary:focus {
      background-color: #ededed !important;
    }
.grants-list {
  margin-top: 3rem;
}
.grants-display {
  border-radius: 8px;
  border: 1px solid #ededed;
  padding: .75rem;
}
.grants-display + .grants-display {
    margin-top: 1.5rem;
  }
.grants-display__mobile-format {
    grid-column: 1/span 3;
  }
.grants-display .heading--h4 {
    align-items: center;
    display: flex;
  }
.grants-display__layout {
    align-items: start;
    display: grid;
    grid-gap: 1.5rem;
    width: 100%;
  }
.grants-display__grid {
    display: grid; 
    grid-gap: 1.5rem;
    grid-template-columns: 1fr;
    padding: 1.5rem 0;
  }
.grants-display__grid .grants-display + .grants-display {
      margin-top: 0;
    }
.grants-display .flex {
    margin: 1.5rem 0;
  }
.grants-display .flex > * {
      margin: .75rem .75rem .75rem 0;
    }
.grants-body__title .heading {
  align-items: center;
  display: flex;
}
.grants-body__title .heading svg {
  margin-left: -.5rem;
}
.scholarships__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.list--checklist svg {
  height: 1rem;
  margin: 2px .5ch 0 0;
}
.field-section + .field-section {
    margin-top: 3rem;
  }
.makeStyles-root-18 > * {
  margin: 0 !important;
}
.tipslist {
  max-height: 0;
  overflow: hidden;
  transition: .2s linear all;
}
.tipslist--expanded {
    max-height: 35rem;
    overflow: auto;
  }
.message {
  border: 1px solid #ededed;
  border-radius: 8px;
}
.message__header {
  display: grid !important;
  font-size: .75rem;
  grid-template-columns: repeat(12, 1fr) !important;
  margin: 0 !important;
  padding: .75rem .75rem 0 .75rem;
  transition: .2s linear all;
}
.message__header-from {
  align-items: center;
  display: flex;
  grid-column: 1/span 9;
}
.message__header-date {
  grid-column: 10/span 3;
  text-align: right;
  font-style: italic;
}
.message__header-title {
  grid-column: 1/span 12;
  grid-row: 2;
  padding: 0 .75rem .75rem 1.35rem;
}
.message__body {
  border-top: solid 1px #ededed;
  font-size: .875rem;
  white-space: pre-line;
}
.message__body-content {
  padding: .75rem .75rem 2rem 2rem !important;
}
.rhythm > * + *, .rhythm--default > * + * { margin-top: 1em; }
.rhythm--small > * + * { margin-top: 0.5em; }
.rhythm--large > * + * { margin-top: 2em; }
.rhythm--extra-large > * + * { margin-top: 4em; }
.rhythm--deep * + *, .rhythm--deep-default * + * { margin-top: 1em; }
.rhythm--deep-small * + * { margin-top: 0.5em; }
.rhythm--deep-large * + * { margin-top: 2em; }
.rhythm--deep-extra-large * + * { margin-top: 4em; }
.text--align-center {
    text-align: center;
  }
.text--align-left {
    text-align: left;
  }
.text--align-right {
    text-align: right;
  }
.text--bold {
    font-weight: 700;
  }
.text--italic {
    font-style: italic;
  }
.text--light {
    font-weight: var(--font-weight--light);
  }
.text--small {
    font-size: .875rem;
  }
.text--small-heavy {
    font-size: .625rem;
    font-weight: 700;
    text-transform: uppercase;
  }
/* Import root styles */
* {
  box-sizing: border-box;
}
html, body {
  box-sizing: inherit;
  color: rgba(51, 51, 51, 1);
  font-family: 'Open Sans', sans-serif;
  line-height: 26px;
  scroll-behavior: smooth;
}
html.lock-body, body.lock-body {
    overflow: hidden;
  }
body {
  padding-top: 3rem;
}
img {
  max-width: 100%;
}
strong {
  font-weight: 700;
}
/* Import all component styles */
.aspect {
  position: relative;
}
.aspect::before {
    display: block;
    content: '';
  }
.aspect > * {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
.aspect--16by9::before {
      padding-top: 56.25%;
      content: '';
    }
.aspect--4by3::before {
      padding-top: 75%;
      content: '';
    }
.aspect--2by1::before {
      padding-top: 50%;
      content: '';
    }
.asterisk {
  color: #6e9e75;
  font-weight: 700;
}
.blockquote, blockquote {
  padding: 1.5rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
}
.blockquote .figcaption, blockquote .figcaption {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.125rem;
    font-weight: 300;
    margin-top: 1rem;
  }
.brand {
  background-image: url(../images/icons/osa-header-graphic.svg);
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 28px;
  transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
  width: 80px;
}
.button, .input[type="submit"] {
  background-color: #1a476e;
  border: 2px solid transparent;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: .75rem;
  font-weight: 700;
  min-height: 2.5rem;
  min-width: 5rem;
  outline: none;
  padding: .5rem 2ch;
  text-decoration: none;
  text-transform: uppercase;
  transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
  user-select: none;
  white-space: nowrap;
}
.button:hover, .input[type="submit"]:hover {
    background-color: #4ea9b0;
    text-decoration: none;
  }
.button:active, .input[type="submit"]:active {
    background-color: #6e9e75;
  }
.button:focus, .input[type="submit"]:focus {
    background-color: #6e9e75;
    outline: #6e9e75 auto 5px;
  }
.button[disabled], .input[type="submit"][disabled] {
    background-color: #9e9e9e;
    cursor: default;
  }
.button--icon, .input[type="submit"]--icon {
    align-items: center;
    display: inline-flex;
  }
.button--icon svg, .input[type="submit"]--icon svg {
      flex: 0 0 auto;
      height: 1rem;
      margin: 0 0 0 1ch;
      width: 1rem;
    }
.button--icon svg path, .input[type="submit"]--icon svg path {
        fill: #ffffff;
        transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
      }
.button.button--outlined, .input[type="submit"].button--outlined {
    background-color: transparent;
    border-color: #6e9e75;
    color: #6e9e75;
  }
.button.button--outlined:hover, .button.button--outlined:active, .input[type="submit"].button--outlined:hover, .input[type="submit"].button--outlined:active {
      background-color: #ffffff;
      border-color: #3f2500;
      color: #3f2500;
    }
.button.button--outlined:focus, .input[type="submit"].button--outlined:focus {
      background-color: #ffffff;
      color: #3f2500;
    }
.button.button--outlined[disabled], .input[type="submit"].button--outlined[disabled] {
      background-color: inherit;
      border-color: #9e9e9e;
      color: #9e9e9e;
    }
.button--text, .input[type="submit"]--text {
    background-color: transparent;
    border: none;
    color: #6e9e75;
    min-height: 1rem;
    min-width: 9rem;
    padding: 0;
  }
.button--text:hover, .button--text:active, .input[type="submit"]--text:hover, .input[type="submit"]--text:active {
      background-color: transparent;
      color: #3f2500;
    }
.button--text:focus, .input[type="submit"]--text:focus {
      background-color: transparent;
      outline: none;
      text-decoration: underline;
    }
.button--text[disabled], .input[type="submit"]--text[disabled] {
      background-color: inherit;
      color: #9e9e9e;
    }
.button--gradient, .input[type="submit"]--gradient {
    background: linear-gradient(220deg, #515139 20%, #3f2500 100%);
  }
.button--gradient:hover, .input[type="submit"]--gradient:hover {
      background: #515139;
    }
.button--gradient:active, .input[type="submit"]--gradient:active {
      background: #3f2500;
    }
.button--gradient:focus, .input[type="submit"]--gradient:focus {
      background: linear-gradient(220deg, #515139 20%, #3f2500 100%);
    }
.button--gradient[disabled], .input[type="submit"]--gradient[disabled] {
      background: #9e9e9e;
    }
.button--white, .input[type="submit"]--white {
    background: #ffffff;
    color: #6e9e75;
  }
.button--white:hover,.button--white:active,.button--white:focus, .input[type="submit"]--white:hover, .input[type="submit"]--white:active, .input[type="submit"]--white:focus {
      background: #ffffff;
      color: #515139;
    }
.button--has-icon, .input[type="submit"]--has-icon {
    align-items: center;
    display: inline-flex;
  }
.card {
  align-items: center;
  background-color: #ffffff;
  border: 0;
  border-bottom: 10px solid transparent;
  border-radius: 5px;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  max-width: 320px;
  padding: 1.5rem;
  text-align: center;
  text-decoration: none;
  transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
}
.card:hover,.card:active,.card:focus {
    background-color: #33797e;
    border-bottom: 10px solid #6e9e75;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    color: #ffffff;
  }
.card:hover svg path,.card:active svg path,.card:focus svg path {
      fill: #ffffff;
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    }
.card:hover .heading,.card:active .heading,.card:focus .heading {
      color: #ffffff;
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    }
.card:hover .richtext,.card:active .richtext,.card:focus .richtext {
      color: #ffffff;
    }
.card:hover .link,.card:active .link,.card:focus .link {
      color: #ffffff;
      pointer-events: none;
    }
.card svg path {
    fill: #8b0c23;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
  }
.card .heading {
    font-size: 1.25rem;
  }
.card .richtext {
    color: rgba(51, 51, 51, 1);
    font-weight: 400;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
  }
.card .link {
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
  }
.component {
  padding-bottom: 3rem;
  padding-top: 3rem;
}
.component--no-padding {
    padding: 0
  }
.component--bottom-padding {
    padding-bottom: 3rem;
    padding-top: 0;
  }
.component--top-padding {
    padding-bottom: 0;
    padding-top: 3rem;
  }
.component--large-padding {
    padding-bottom: calc(3rem * 1.5);
    padding-top: calc(3rem * 1.5);
  }
.component--bottom-large-padding {
    padding-bottom: calc(3rem * 1.5);
    padding-top: 0;
  }
.component--top-large-padding {
    padding-bottom: 0;
    padding-top: calc(3rem * 2);
  }
.component--larger-padding {
    padding-bottom: calc(3rem * 2);
    padding-top: calc(3rem * 2);
  }
.component--bottom-larger-padding {
    padding-bottom: calc(3rem * 2);
    padding-top: 0;
  }
.component--top-larger-padding {
    padding-bottom: 0;
    padding-top: calc(3rem * 2);
  }
.component--small-padding {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }
.component--bottom-small-padding {
    padding-bottom: 1.5rem;
    padding-top: 0;
  }
.component--top-small-padding {
    padding-bottom: 0;
    padding-top: 1.5rem;
  }
.dl {
  display: flex;
  flex-wrap: wrap;
}
.dl .dt {
    flex: 1 0 33%;
    font-weight: 700;
  }
.dl .dt::after {
      content: ': ';
    }
.dl .dd {
    flex: 0 1 67%;
  }
.figure {
  /* Figure */
}
/* Standard Eric Meyer reset */
/* Get the variables in early so they're available */
/* Import the utility classes */
/* Import root styles */
/* Import all component styles */
.form__item {
    padding: 1.5rem 0;
  }
.form__item + .form__item {
      padding: 0 0 1.5rem 0;
    }
.google-map {
  padding-bottom: 62.4%;
  position: relative;
}
.google-map iframe {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
.heading-eyebrow {
  display: block;
  font-size: .75rem;
  margin-bottom: -1.5rem;
  text-transform: uppercase;
}
.heading-eyebrow--prominent {
    background-color: #3f2500;
    color: #ffffff;
    display: inline-block;
    font-size: 1.25rem;
    padding: 1.25rem 2.5rem;
  }
.heading, .richtext h1, .richtext h2, .richtext h3, .richtext h4, .richtext h5, .richtext h6 {
  color: rgba(51, 51, 51, 1);
  font-family: 'Open Sans', sans-serif;
  font-range: 320px 1600px; /* viewport widths between which font-size is fluid */
  font-weight: 700;
  line-height: 1.2em;
}
.heading--h1, .richtext h1 {
    font-size: calc(2.125rem + 0.375 * ((100vw - 26.25rem) / 53.75));
  }
.heading--h2, .richtext h2 {
    font-size: calc(1.875rem + 0.125 * ((100vw - 26.25rem) / 53.75));
  }
.heading--h3, .richtext h3 {
    font-size: calc(1.5rem + 0 * ((100vw - 26.25rem) / 53.75));
  }
.heading--h4, .richtext h4 {
    font-size: calc(1.25rem + 0 * ((100vw - 26.25rem) / 53.75));
  }
.heading--h5, .richtext h5 {
    font-size: calc(1.125rem + 0 * ((100vw - 26.25rem) / 53.75));
  }
.heading--h6, .richtext h6 {
    font-size: calc(1rem + 0 * ((100vw - 26.25rem) / 53.75));
  }
.heading--enormous {
    display: block;
    font-family: 'Open Sans', sans-serif;
    font-size: calc(5rem + 7.5 * ((100vw - 26.25rem) / 53.75));
    font-weight: 900;
    text-transform: uppercase;
  }
.hr {
  border: none;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.hr--default {
    background: #d8d8d8;
  }
.hr--dashed {
    border-top: 2px dashed #6e9e75;
  }
.hr--green {
    background: 2px var(--color-green);
  }
.hr--red {
    background: 2px #3f2500;
  }
.hr--turquoise {
    background: 2px var(--color-secondary-5);
  }
.hr--violet {
    background: 2px #8b0c23;
  }
.hr--yellow {
    background: 2px var(--color-secondary-6);
  }
h1 i, h2 i, h3 i, h4 i, h5 i, .heading .i, .richtext h1 .i, .richtext h2 .i, .richtext h3 .i, .richtext h4 .i, .richtext h5 .i, .richtext h6 .i {
  color: inherit;
  display: inline-block;
  font-style: normal;
  position: relative;
  z-index: 2;
}
h1 i::before, h2 i::before, h3 i::before, h4 i::before, h5 i::before, .heading .i::before, .richtext h1 .i::before, .richtext h2 .i::before, .richtext h3 .i::before, .richtext h4 .i::before, .richtext h5 .i::before, .richtext h6 .i::before {
    background-color: #53636c;
    content: '';
    display: inline-block;
    height: 40%;
    left: -.25ch;
    position: absolute;
    top: 30%;
    width: calc(98% + .5ch);
    z-index: -1;
  }
i {
  font-style: italic;
}
.icon {
  display: inline-block;
  height: calc(7.125rem - 2rem);
  max-height: 100%;
  max-width: 100%;
  width: calc(7.125rem - 2rem);
}
.icon use {
    height: 100%;
    width: 100%;
  }
.icon--brand {
    height: 28px;
    width: 80px;
  }
.image, .richtext img {
  border-radius: 5px;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}
.image--background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
.image__lazy-box {
      bottom: 4px;
      left: 0;
      opacity: 1;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 3;
    }
.image__lazy-box .image__low-res {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
        width: 100%;
      }
.image__lazy-panel {
      background-color: #d8d8d8;
      display: block;
      height: 100%;
      opacity: 0;
      position: absolute;
      width: 100%;
      z-index: 3;
    }
.image__lazy-wrapper {
      display: inline-block;
      position: relative;
    }
.image__lazy-wrapper.pre-load .image__low-res {
          filter: blur(6px);
        }
.image__lazy-wrapper.pre-load .image__lazy-panel {
          opacity: .5;
        }
.image__lazy-wrapper.is-loaded .image__low-res {
          filter: none;
        }
.image__lazy-wrapper.is-loaded .image__lazy-box {
          opacity: 0;
          transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
        }
.image__lazy-wrapper.is-loaded .image.is-lazy {
          opacity: 1;
          transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
        }
.input {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  color: rgba(51, 51, 51, 1);
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: .875rem;
  line-height: 1;
  padding: .75rem;
}
.input:focus {
    outline-color: var(--color-p1);
  }
.input::placeholder {
    color: var(--color-gray-4);
    opacity: .8;
  }
.input[type="text"], .input[type="email"], .input[type="tel"], .input[type="number"] {

    width: 100%;
  }
.input[type="file"] {
    border-color: transparent;
  }
.input[type="date"], .input[type="datetime-local"], .input[type="time"], .input.select {
    background-position: top 50% right .5rem;
    background-repeat: no-repeat;
    min-height: 40px;
  }
.input.select {
    background-image: url('../images/icons/select.svg');
  }
.input[type="date"]:hover,.input[type="date"]:active,.input[type="date"]:focus, .input[type="datetime-local"]:hover, .input[type="datetime-local"]:active, .input[type="datetime-local"]:focus, .input[type="time"]:hover, .input[type="time"]:active, .input[type="time"]:focus {
      background-image: none !important;
    }
.input[type="checkbox"], .input[type="radio"] {
    cursor: pointer;
    position: relative;
    top: .15rem;
    zoom: 1.5;
  }
.input[type="checkbox"] + .label, .input[type="checkbox"] + label, .input[type="radio"] + .label, .input[type="radio"] + label {
      cursor: pointer;
      display: inline-block;
      font-size: .875rem;
      font-weight: 400;
    }
.label {
  color: rgba(51, 51, 51, 1);
  cursor: pointer;
  display: block;
  font-size: .75rem;
  font-weight: 600;
}
/* LanguageSelect */
.language-select option {
    color: #000;
  }
.link, .richtext a {
  color: #33797e;
  text-decoration: underline;
  transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
}
.link:hover,.link:active,.link:focus, .richtext a:hover, .richtext a:active, .richtext a:focus {
    color: #33797e;
    text-decoration: underline;
  }
.link--button, .link.button, .richtext a.button {
    background-color: #1a476e;
    border: 2px solid #1a476e;
    border-radius: 3px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    min-width: 8.625rem;
    padding: .75rem 2.25rem;
    text-align: center;
    text-decoration: none;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    user-select: none;
  }
.link--button:hover,.link--button:active,.link--button:focus, .link.button:hover, .link.button:active, .link.button:focus, .richtext a.button:hover, .richtext a.button:active, .richtext a.button:focus {
      background-color: #0e95bc;
      border-color: transparent;
      color: #ffffff;
      text-decoration: none;
    }
.link.button--outline, .richtext a.button--outline {
    background-color: #ffffff;
    border: 2px solid #1a476e;
    color: #1a476e;
  }
.link.button--outline:hover,.link.button--outline:active,.link.button--outline:focus, .richtext a.button--outline:hover, .richtext a.button--outline:active, .richtext a.button--outline:focus {
      background-color: #0e95bc;
      border-color: #0e95bc;
      color: #ffffff;
    }
.link.button--white, .richtext a.button--white {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    color: #1a476e;
  }
.link.button--white:hover,.link.button--white:active,.link.button--white:focus, .richtext a.button--white:hover, .richtext a.button--white:active, .richtext a.button--white:focus {
      background-color: #0e95bc;
      border-color: #0e95bc;
      color: #ffffff;
    }
.link--block, .link--block-white, .link--block-icon, .link--block-white-icon {
    color: rgba(51, 51, 51, 1);
    display: inline-block;
    font-size: .875rem;
    font-weight: 600;
    text-transform: uppercase;
  }
.link--block-icon, .link--block-white-icon {
    background-image: url(../images/icons/link-block-black.svg);
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 2rem;
  }
.link--block-white-icon {
    background-image: url(../images/icons/link-block.svg);
    color: #ffffff;
  }
.link--block-white-icon:hover,.link--block-white-icon:active,.link--block-white-icon:focus {
      color: #ffffff;
    }
.link--block-white {
    color: #ffffff;
  }
.link--block-white:hover,.link--block-white:active,.link--block-white:focus {
      color: #ffffff;
    }
.link--uppercase {
    display: inline-block;
    font-size: .875rem;
    font-weight: 700;
    text-transform: uppercase;
  }
.list, .richtext ol, .richtext ul {
  list-style: unset;
  padding-left: 1.5rem;
}
.list .li, .richtext ol .li, .richtext ul .li {
    margin: 0 0 .5em 0;
  }
.list--blank {
    list-style-type: none;
    padding-left: 0;
  }
.list--inline {
    list-style-type: none;
    padding-left: 0;
  }
.list--inline .li {
      display: inline-block;
      margin: 0 1em 0 0;
    }
.list--ordered, .richtext ol {
    list-style-type: decimal;
  }
.list--unordered, .richtext ul {
    list-style-type: disc;
  }
.media-object {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* alignment variants */
.media-object--top { align-items: flex-start; }
.media-object--middle { align-items: center; }
.media-object--bottom { align-items: flex-end; }
/* sizing variants */
.media-object--2-3 > :first-child {
      flex-basis: 35%;
    }
.media-object--2-3 > :last-child {
      flex-basis: 60%;
    }
.media-object--3-2 > :first-child {
      flex-basis: 60%;
    }
.media-object--3-2 > :last-child {
      flex-basis: 35%;
    }
.media-object--50-50 > :first-child {
      flex-basis: 50%;
    }
.media-object--50-50 > :last-child {
      flex-basis: 50%;
    }
.media-object__figure {
    margin-bottom: 2rem;
    order: -10;
  }
.media-object--no-stack .media-object__figure {
      margin-bottom: 0;
      margin-top: 2rem;
      order: 0;
    }
/* figure alignment variants */
.media-object__figure--top { align-self: flex-start; }
.media-object__figure--middle { align-self: center; }
.media-object__figure--bottom { align-self: flex-end; }
/* body alignment variants */
.media-object__body--top { align-self: flex-start; }
.media-object__body--middle { align-self: center; }
.media-object__body--bottom { align-self: flex-end; }
.paragraph, .richtext p {
  display: block;
}
.paragraph--taller {
    line-height: 1.6;
  }
.photo-card.card {
    align-items: stretch;
    background-color: #ffffff;
    border-bottom: 0;
    max-width: 27rem;
    padding: 1.5rem 0; 
    text-align: left;
  }
.photo-card.card:hover,.photo-card.card:active,.photo-card.card:focus {
      background: #ffffff;
      color: rgba(51, 51, 51, 1);
    }
.photo-card.card:hover .heading,.photo-card.card:active .heading,.photo-card.card:focus .heading, .photo-card.card:hover .richtext, .photo-card.card:active .richtext, .photo-card.card:focus .richtext {
        color: rgba(51, 51, 51, 1);
      }
.photo-card.card:hover .link,.photo-card.card:active .link,.photo-card.card:focus .link {
        color: #0e95bc;
        pointer-events: unset;
      }
.photo-card__heading {
    padding: 0 1.5rem;
    min-height: 3rem;
  }
.photo-card .figure {
    flex: 1 1 auto;
    padding-top: 1.5rem;
  }
.photo-card .figcaption {
    padding: 1.5rem;
  }
.photo-card .list {
    padding: 0 1.5rem;
  }
.picture__img {
    max-width: 100%;
    vertical-align: bottom;
  }
.picture--stretched .picture__img {
      width: 100%;
    }
.richtext strong {
    font-weight: 700;
  }
.richtext em {
    font-style: italic;
  }
.richtext li {
    margin: 0 0 .5rem 0;
  }
.richtext sub, .richtext sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
.richtext sup {
    /* Move the superscripted text up */
    top: -.5em;
  }
.richtext sub {
    /* Move the subscripted text down, but only
       half as far down as the superscript moved up */
    bottom: -.25em;
  }
.richtext table {
    display: block;
    width: 100%;
  }
.richtext table tbody, .richtext table tfoot, .richtext table tr {
      display: block;
    }
.richtext table tr:nth-child(even) {
        background-color: var(--color-gray-4);
      }
.richtext table th, .richtext table td {
      border-bottom: 1px dotted #9e9e9e;
      display: flex;
      justify-content: flex-end;
      padding: .5rem;
    }
.richtext table th {
      display: none;
      font-weight: 700;
      text-align: left;
    }
.richtext table td::before {
        content: attr(data-heading);
        flex: 1 1 auto;
        font-weight: 700;
        text-align: left;
      }
.richtext table td > * {
        text-align: right;
      }
.select {
  appearance: none;
  border-radius: 3px;
  display: inline-block;
  font-weight: 400;
  padding-right: 2rem;
}
.split-media-card {
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%);
  position: relative;
}
.split-media-card__layout {
    display: flex;
    flex-direction: column;
  }
.split-media-card__caption {
    margin-top: 1.5rem;
    order: 2;
    padding: 1.5rem;
  }
.split-media-card__image {
    order: 1;
    padding: 1.5rem 1.5rem 0 1.5rem;
  }
.split-media-card__image .image {
      border-radius: 5px;
    }
.svg {
  /* Svg */
}
.table-wrapper {
  max-width: 100%;
  overflow-x: auto;
}
.table, table {
  border: solid 1px #ededed;
  border-collapse: border-collapse;
  font-size: .875rem;
  margin: 1.5rem 0 3rem;
  min-width: 36rem;
  width: 100%;
}
.table .table .MuiButtonBase-root, table .table .MuiButtonBase-root {
  padding: 0;
}
.table__caption, table__caption {
    border-bottom: solid 1px #ededed;
    caption-side: top;
    font-size: 1.125rem;
    font-weight: 700;
    padding: .75rem 0 .5rem;
  }
.table th, .table td, table th, table td {
    border-bottom: 1px solid #ededed;
    padding: .5rem .75rem;
  }
.table th, table th {
    background-color: #ededed;
    font-size: .75rem;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
  }
.textarea {
  min-height: 6rem;
  overflow: auto;
  resize: vertical;
  width: 100%;
}
.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 100rem;
  padding: 0 1.5rem;
  position: relative;
  width: 100%;
}
.wrapper--featured {
    padding: 2rem 1rem;
  }
.wrapper--narrow {
    max-width: 80rem;
  }
.wrapper .wrapper {
    padding-left: 0;
    padding-right: 0;
  }
.accordion {
  border-bottom: 1px solid var(--color-gray-4);
  padding: 1rem 0;
}
.accordion__head {
    border-bottom: 4px solid #3f2500;
    padding-bottom: 3rem;
    text-align: center;
  }
.accordion__head-text {
    flex: 1 1 auto;
  }
.accordion__head-toggle {
    align-items: flex-end;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
  }
.accordion__toggleall-collapse {
      display: none;
    }
.accordion__toggleall.is-active .accordion__toggleall-expand {
        display: none;
      }
.accordion__toggleall.is-active .accordion__toggleall-collapse {
        display: inline;
      }
.accordion__drawer {
    border-radius: 5px;
    margin-bottom: 0;
  }
.accordion__drawer .expandable__drawer {
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    }
.accordion__heading {
    color: #1a476e;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    padding: .5rem 2rem .5rem 0;
    position: relative;
  }
.accordion__heading:focus {
      outline: none;
    }
.accordion__heading::after {
      background-color: #1a476e;
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      right: 0;
      top: 1rem;
      transform-origin: center;
      width: .75rem;
    }
.accordion__heading::before {
      background-color: #1a476e;
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      right: 0;
      transform: rotate(90deg);
      transform-origin: center;
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
      top: 1rem;
      width: .75rem;
    }
.accordion__heading[aria-expanded="true"]::before {
        transform: rotate(180deg);
        content: '';
      }
.accordion--content .accordion__heading {
      background-color: #1a476e;
      border-radius: 5px;
      color: #ffffff;
      padding: .75rem 1.5rem;
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s; 
    }
.accordion--content .accordion__heading::before, .accordion--content .accordion__heading::after {
        background-color: #ffffff;
        left: unset;
        right: 1.5rem;
        top: 1.25rem;
      }
.accordion--content .accordion__heading[aria-expanded="true"] {
        background-color: #0e95bc;
      }
.accordion--content .expandable__drawer {
      padding: 1.5rem;
    }
.card-deck__layout {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr;
    justify-items: center;
  }
.card-deck--horizontal, .card-deck--horizontal-3up {
    background-color: #2B6063;
    padding: 1.5rem;
  }
.card-deck--horizontal .card-deck__heading, .card-deck--horizontal-3up .card-deck__heading {
      align-items: center;
      border-bottom: 5px solid #6e9e75;
      display: flex;
      justify-content: space-between;
      margin-bottom: 3rem;
      padding: 1.5rem 0;
    }
.card-deck--horizontal .card-deck__heading .heading, .card-deck--horizontal-3up .card-deck__heading .heading {
        color: #ffffff;
      }
.card-deck--horizontal .card-deck__heading .link, .card-deck--horizontal-3up .card-deck__heading .link {
        background-image: url(../images/icons/link-block-light-blue.svg);
        color: #b7dff6;
      }
.card-deck--horizontal .card-deck__layout, .card-deck--horizontal-3up .card-deck__layout {
      position: relative;
      z-index: 0;
    }
.card-deck--split-media-card {
    padding: 1.5rem;
  }
.expandable {
  overflow: hidden;
  transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
}
.expandable:not(.is-expanded) .expandable__drawer {
      box-shadow: inset 0 -2px 5px 0 var(--color-gray-4);
    }
.expandable__toggle {
    user-select: none;
  }
.featured-split-media {
  background-color: #153047;
  color: #ffffff;
  margin-bottom: -3rem;
  padding: 3rem;
}
.featured-split-media .richtext a {
      color: #c2c9d1;
    }
.featured-split-media__header {
    border-bottom: 5px solid #b7dff6;
    margin-bottom: 3rem;
    padding-bottom: 1.5rem;
  }
.featured-split-media__header .heading {
      color: #ffffff;
    }
.featured-split-media__figure {
    background-color: #ffffff;
    border-radius: 5px;
    color: rgba(51, 51, 51, 1);
    padding: 3rem 1.5rem;
    margin: 3rem 0;
  }
.featured-split-media__image {
    display: flex;
    justify-content: center;
  }
.featured-split-media__image .image {
      border-radius: 5px;
    }
.featured-split-media__layout {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 1fr;
  }
.full-width-media .figcaption {
    color: #666666;
    font-size: .875rem;
  }
.full-width-media .image, .full-width-media img {
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    width: 100%;
  }
.global-footer {
  background-color: #20575b;
  color: #ffffff;
  margin-top: 3rem;
  padding: 3rem 1.5rem;
}
.global-footer__brand {
    margin-bottom: 3rem;
  }
.global-footer__navigation {
    font-size: .875rem;
  }
.global-footer__legal {
    color: #ffffff;
    font-size: .6875rem;
    margin-top: 3rem;
  }
.global-footer__connect {
    font-size: .875rem;
    margin-top: 3rem;
  }
.global-footer__connect .link {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      height: 2rem;
      opacity: 1;
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
      width: 2rem;
    }
.global-footer__connect .link:hover,.global-footer__connect .link:active,.global-footer__connect .link:focus {
        opacity: .7;
      }
.global-footer__connect .link + .link {
        margin-left: 1rem;
      }
.global-footer__connect-twitter {
      background-image: url(../images/icons/twitter.svg);
    }
.global-footer__connect-instagram {
      background-image: url(../images/icons/instagram.svg);
    }
.global-footer__connect-facebook {
      background-image: url(../images/icons/facebook.svg);
    }
.global-footer__social-links {
    padding-top: .5rem;
  }
.global-footer__nav {
    font-size: .875rem;
    margin-bottom: 1.5rem;
  }
.global-footer__nav .link {
      color: #ffffff;
      line-height: 1;
      text-decoration: none;
    }
.global-footer__nav .link:hover,.global-footer__nav .link:active,.global-footer__nav .link:focus {
        text-decoration: underline;
      }
.global-footer .heading--h6 {
    border-bottom: 1px solid rgba(196, 196, 196, 1);
    color: #ffffff;
    font-size: 1.125rem;
    padding-bottom: 1rem;
  }
.global-header {
  background-color: #20575b;
  border-bottom: 1px solid #99D5CA;
  height: calc(3rem + 2px);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
  z-index: 200;
}
.global-header__layout {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 3rem;
    justify-content: space-between;
  }
.global-header__brand {
    margin-top: .25rem;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
  }
.global-header__backdrop {
    background-color: transparent;
    bottom: 0;
    content: '';
    display: block;
    height: 100vh;
    left: 0;
    pointer: initial;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    width: 100vw;
    z-index: -1;
  }
.sub-open .global-header__backdrop {
      background-color: rgba(0, 0, 0, .25);
      pointer-events: all;
      z-index: 205;
    }
.global-header__navigation {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: flex-end;
  }
/* TODO */
.global-header__navigation > .nav-utility {
      transform: translateX(-2.875rem);
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    }
.global-header__navigation > .navigation > .nav-item {
        opacity: 0;
        transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
      }
.global-header__utility {
    display: none;
    font-size: .875rem;
    padding: .5rem 0;
  }
.global-header__utility .link {
      align-items: center;
      border: 0;
      color: #ffffff;
      display: flex;
      font-size: .875rem;
      margin: 0 1rem;
      text-decoration: none;
    }
.global-header__utility .link svg {
        margin-right: 1ch;
      }
.global-header__utility .link svg path {
          fill: #ffffff;
        }
.global-header__utility .link + link {
        margin-right: 0;
      }
.global-header__searchfield {
    background-color: transparent;
    background-image: url(../images/icons/search.svg);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    border-bottom: 1px solid transparent;
    border-color: transparent;
    border-radius: 0;
    color: #ffffff;
    margin-right: 1rem;
    min-width: 0;
    outline: 0 !important;
    padding: 0;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    width: 5rem;
  }
.global-header__searchfield::placeholder {
      color: #ffffff;
    }
.global-header__searchfield:focus {
      border-bottom: 1px solid #ffffff;
      width: 12rem;
    }
.global-header__portal {
    background-image: url(../images/icons/portal.svg);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    padding-right: 1.5rem;
  }
.global-header__help {
    background-image: url(../images/icons/help.svg);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 11px 15px;
    padding-right: 1.25rem;
  }
.global-header__locations {
    margin-left: 0 !important;
  }
.global-header__mobile-search {
    margin-top: 4rem;
  }
.global-header__mobile-search-layout {
    position: relative;
  }
.global-header__mobile-search-layout .input--mobile-search-text {
      padding-right: 1.5rem;
      width: 100%;
    }
.global-header__mobile-search-layout .input--search-submit {
      background-color: transparent;
      border: 0;
      bottom: .5rem;
      position: absolute;
      right: .5rem;
    }
.global-header .language-select .input {
      background-color: transparent;
      border: 0;
      border-radius: 0;
      color: #ffffff;
      cursor: pointer;
      min-width: 0;
    }
.global-header--portal .global-header__logout {
      margin-right: 0;
    }
.nav-item {
  align-items: center;
  background-color: transparent;
  display: inline-flex;
  flex-grow: 0;
  outline: 0;
  padding: 0 .5rem 0 0;
  text-align: left;
  transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
  width: 100%;
}
.nav-item.is-open .nav-item__menu {
      opacity: 1;
      pointer-events: all;
      right: -1px;
    }
.nav-item.is-hidden {
    padding-right: 0;
    padding-left: 0;
    opacity: 0;
    width: 0;
  }
.nav-item:last-of-type {
    /* background-color: pink; */
  }
.nav-item[hidden] {
    display: none;
    pointer-events: none;
  }
.nav-item__menu-items {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: auto;
  }
.nav-item__menu-items .link {
      background-color: transparent;
      border: 0;
      color: rgba(51, 51, 51, 1);
      display: inline-block;
      flex: 0 0 25%;
      font-size: .9375rem;
      font-family: 'Open Sans', sans-serif;
      line-height: 1.2;
      font-weight: 600;
      padding: 1rem 1.5rem 1rem 1.5rem;
      text-align: left;
      text-decoration: none;
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    }
.nav-item__menu-items .link:hover,.nav-item__menu-items .link:active,.nav-item__menu-items .link:focus {
        background-color: #cae6ec;
        text-decoration: none;
      }
.nav-item__menu-items .link:focus {
        outline: none;
      }
.nav-item__menu {
    background-color: #ffffff;
    bottom: 0;
    height: calc(100vh - 3rem);
    max-width: 320px;
    min-height: 450px;
    opacity: 1;
    overflow: auto;
    padding: 4rem 0 3rem 0;
    pointer-events: none;
    position: fixed;
    right: -320px;
    top: calc(3rem + 2px);
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    width: 100%;
    z-index: 210;
  }
.nav-item__link, .nav-item__label, .nav-item__toggle {
    align-items: center;
    color: #000000;
    cursor: pointer;
    font-family: 'Open Sans';
    font-size: .875rem;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0;
    text-decoration: none;
    opacity: 1;
    outline: none;
    position: relative;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    white-space: nowrap;
  }
.nav-item__toggle {
    align-content: flex-start;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    height: 4.1255rem;
    opacity: 1;
    outline: none;
    padding: 0;
    position: relative;
    text-align: left;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
  }
.nav-item__link .nav-item__label {
      padding: 0;
    }
.nav-item__label {
    align-items: center;
    color: #ffffff;
    display: inline-flex;
    height: 3.875rem;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
.nav-item__link {
    display: block;
    opacity: 1;
    outline: none;
    position: relative;
    text-align: left;
    text-decoration: none;
  }
.nav-item__link:hover .nav-item__label,.nav-item__link:active .nav-item__label,.nav-item__link:focus .nav-item__label {
        color: #b7dff6;
      }
.navigation {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
.navigation__drawer {
    align-items: center;
    background-color: #27363f;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.075);
    height: calc(100vh - 3rem);
    overflow: auto;
    padding: 1.5rem;
    position: fixed;
    right: -100%;
    top: calc(3rem + 2px);
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    width: 100%;
    z-index: 200;
  }
.navigation__drawer[hidden] {
      z-index: -1;
    }
.navigation__toggle {
    background: transparent;
    border: none;
    border-left: 1px solid #99D5CA;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: column;
    height: 3rem;
    justify-content: space-between;
    opacity: 1;
    padding: 1.125rem .75rem .5rem;
    position: relative;
    right: -1.5rem;
    text-align: center;
    top: 0px;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    width: 3rem;
    z-index: calc(200 + 1);
  }
.navigation__toggle > span {
      background-color: #ffffff;
      height: 2px;
      display: block;
      left: 0;
      opacity: 1;
      position: relative;
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
      width: 100%;
    }
.navigation__toggle > span:nth-child(1) {
        top: -1px;
        transform-origin: top left;
        width: 100%;
      }
.navigation__toggle > span:nth-child(3) {
        transform-origin: bottom left;
        width: 100%;
      }
.navigation.nav-open .navigation__drawer {
      right: 0;
    }
.navigation.nav-open .navigation__toggle > span:nth-child(1) {
        left: -2px;
        top: -2px;
        transform: translateY(-1px) translateX(5px) rotate(45deg);
        width: 128%;
      }
.navigation.nav-open .navigation__toggle > span:nth-child(2) {
        opacity: 0;
      }
.navigation.nav-open .navigation__toggle > span:nth-child(3) {
        left: -2px;
        top: 2px;
        transform: translateX(5px) rotate(-45deg);
        width: 128%;
      }
.navigation.nav-open + .nav-utility > .nav-item {
      opacity: 0;
    }
.navigation.sub-open .navigation__back {
      opacity: 1;
      pointer-events: initial;
    }
.navigation.sub-open .global-header__backdrop {
      background-color: rgba(0, 0, 0, 0.1);
      pointer-events: initial;
      z-index: 200;
    }
.navigation__back {
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    height: 2rem;
    outline: none;
    justify-content: space-between;
    opacity: 0;
    padding: .5rem;
    position: absolute;
    right: 210px;
    text-align: center;
    top: 4rem;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    z-index: calc(200 + 1);
  }
.navigation__back[hidden] {
      pointer-events: none;
      opacity: 0;
    }
.navigation__back > .icon {
      fill: #3f2500;
      min-height: unset;
      min-width: unset;
      stroke: transparent;
    }
.input-checkbox {
  padding: calc(1.5rem / 2) 0;
}
.input-radio {
  padding: calc(1.5rem / 2) 0;
}
.link-list .heading {
    font-size: 1.225rem;
  }
.logo {
  background-color: transparent;
  display: inline-block;
  height: calc(50% + 2rem);
  padding: .5rem .75rem;
  position: relative;
  width: 5.125rem;
}
.logo__icon {
    display: block;
    height: auto;
    max-height: calc(7.125rem - 2rem);
    max-width: 8.75rem;
    width: auto;
  }
.logo__icon::-moz-focus-inner {
      border: 0;
    }
.logo.has-mobile {
    width: calc(2.8125rem + .5rem + .5rem);
  }
.logo.has-mobile .logo__icon {
      display: none;
    }
.logo.has-mobile .logo__icon--mobile {
        display: block;
      }
.logo__inner-wrap {
    display: inline-block;
    height: 100%;
    max-height: 7.125rem;
    position: relative;
    width: 100%;
    z-index: 200;
  }
.logo__inner-wrap::-moz-focus-inner {
      border: 0;
    }
.logo__inner-wrap::after {
      bottom: -.5rem;
      content: '';
      left: -.5rem;
      position: absolute;
      right: -.5rem;
      top: -.5rem;
    }
.logo__inner-wrap:focus {
      outline: 0;
    }
.logo__inner-wrap:focus::after {
        border: 3px solid #00b0ff;
        box-shadow: 0 0 4px 0 #00b0ff;
        content: '';
      }
.marquee {
  margin-bottom: 3rem;
}
.marquee .richtext a {
      color: #c2c9d1;
    }
.marquee .image {
    border-radius: 0;
    height: 100%;
    object-fit: cover;
    vertical-align: bottom;
    width: 100%;
  }
.marquee .heading {
    color: #ffffff;
  }
.marquee .wrapper {
    padding: 0;
  }
.marquee__media {
    text-align: center;
  }
.marquee__content {
    align-items: center;
    background-color: #153047;
    color: #ffffff;
    display: flex;
    padding: 1.5rem;
  }
.marquee__cta {
    margin-top: 1.5rem;
  }
.marquee__cta .link {
      border-color: #ffffff;
      margin: 1.5rem 1.5rem 0 0;
    }
.marquee__login {
    margin-top: 1.5rem;
  }
.media-text-wrap {
  overflow: hidden;
}
.media-text-wrap .figure, .media-text-wrap .image {
    float: right;
  }
.media-text-wrap .figure {
    border-radius: 5px;
    border: 1px solid #d8d8d8;
    margin: 0 0 1.5rem;
  }
.media-text-wrap .figcaption {
    color: rgba(51, 51, 51, 1);
    font-size: .875rem;
    padding: .75rem;
  }
.media-text-wrap .image {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 1rem;
    width: 100%;
  }
.media-text-wrap .richtext {
    padding-bottom: 1.5rem;
  }
.media-text-wrap .heading {
    margin-bottom: 1.5rem;
  }
.modal {
  --modal-icon-color: currentColor;
  transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
}
.modal__close {
    align-items: center;
    background-color: transparent;
    border: none;
    color: #3f2500;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 1rem;
    height: 3rem;
    justify-content: flex-end;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: calc(400 + 1);
  }
.modal__close-icon {
      height: auto;
      margin: 1.5ch 0 3ch;
      width: 2rem;
    }
.modal__dialog {
    background-color: transparent;
    border: none;
    color: #ffffff;
    left: 1rem;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 3rem 0 0;
    position: fixed;
    top: 8rem;
    width: calc(100vw - 2rem);
    z-index: calc(400 + 1);
  }
.modal__dialog[open] {
      display: block;
    }
.modal__overlay {
    background-color: #9e9e9e;
    bottom: 0;
    cursor: pointer;
    height: 100vh;
    left: 0;
    opacity: .5;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 400;
  }
.modal--alternate .modal__close {
      right: 1.75rem;
      top: 1rem;
    }
.modal--alternate .modal__dialog {
      background-color: #ffffff;
      border-radius: 25px;
      color: rgba(51, 51, 51, 1);
      padding: 1.5rem;
      top: 1.5rem;
    }
.modal[aria-hidden='true'] {
  display: none;
}
[data-a11y-dialog-native] > .modal__overlay {
  display: none;
}
.modal__dialog[open] + &__overlay {
  display: block;
}
#signin-modal .modal__overlay {
    opacity: 0;
  }
.overview {
  text-align: center;
}
.page-aside__header {
    border-bottom: 1px solid #ededed;
    margin-bottom: 1.5rem;
    padding: 0 0 1.5rem 0;
  }
.page-aside .heading {
    text-transform: uppercase;
  }
.page-aside .link-list {
    padding: 1.5rem 0 0;
  }
.page-nav ul {
    list-style: none;
  }
.page-nav li {
    padding-left: 1rem;
  }
.page-nav a {
    color: #1a476e;
    font-size: .875rem;
    text-decoration: none;
  }
.page-nav a:hover,.page-nav a:active,.page-nav a:focus {
      text-decoration: underline;
    }
.page-nav a.is-active {
      color: rgba(51, 51, 51, 1);
      cursor: initial;
      font-weight: 700;
      pointer-events: none;
    }
.page-nav a.top-level {
      display: inline-block;
      font-size: 1rem;
      font-weight: 700;
      margin: 1rem 0 1rem -1rem;
    }
.page-nav .accordion {
    padding: 0;
  }
.page-nav .accordion + .accordion {
      margin-top: .5rem;
    }
.page-nav .accordion__heading {
    font-size: 1rem;
  }
.page-nav .expandable__drawer {
    padding-top: 0;
  }
.page-nav__heading {
    border-bottom: 1px solid transparent;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
  }
.page-nav__heading .heading {
      font-size: 1rem;
      text-transform: uppercase;
      user-select: none;
    }
.page-nav__heading .heading::before, .page-nav__heading .heading::after {
        top: 1.125rem;
      }
.is-open .page-nav__heading .heading::before {
          transform: rotate(180deg);
          content: '';
        }
.page-nav__menu {
    overflow: hidden;
    max-height: 0;
    transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
  }
.page-nav.is-open .page-nav__heading {
      border-bottom: 1px solid #ededed;
      padding-bottom: .75rem;
      margin-bottom: 1.5rem;
    }
.page-nav.is-open .page-nav__menu {
      max-height: 100vh;
      overflow: auto;
      padding-bottom: 1.5rem;
    }
.pagination__layout {
    align-items: center;
    display: flex;
    justify-content: space-around;
  }
.pagination .list {
    display: flex;
  
  }
.split-media .image {
    border-radius: 5px;
    margin-bottom: 1.5rem;
    width: 100%;
  }
.split-text__layout {
    display: grid;
    grid-gap: 1.5rem;
  }
.split-text__subtext {
    font-weight: 400;
  }
.tabs {
  max-width: 100%;
}
.tabs__tab-list {
    display: flex;
    justify-content: center;
    text-align: center;
  }
.tabs__tab {
    border: 0;
    background: none;
    color: rgba(51, 51, 51, 1);
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    padding: 1rem 2rem;
    position: relative;
    text-transform: uppercase;
  }
.tabs__tab:focus {
      outline: none;
    }
.tabs__tab:hover,.tabs__tab:active,.tabs__tab:focus {
      background-color: #d8d8d8;
    }
.tabs__tab::after {
      background-color: #6e9e75;
      content: '';
      display: block;
      height: 4px;
      left: 0;
      max-width: 0;
      position: absolute;
      top: 100%;
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
      width: 100%;
      z-index: 100;
    }
.tabs__tab.is-active::after {
      max-width: 100%;
      content: '';
    }
.tabs__panel-list {
    max-width: 100%;
    padding: 1.5rem;
  }
.tabs__panel {
    max-width: 100%;
  }
.text-only--centered {
    text-align: center;
  }
.umbraco-form .turnstyle-grid--1up, .umbraco-form .turnstyle-grid--2up, .umbraco-form .turnstyle-grid--3up, .umbraco-form {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr;
  }
.umbraco-form .umbraco-forms-fieldset {
    display: block;
  }
.umbraco-form legend {
    display: block;
    font-weight: 700;
    padding: 1.5rem 0 1.5rem 0;
  }
.umbraco-form .umbraco-forms-label{
    white-space: pre-line;
  }
.umbraco-form .help-block {
    font-size: .75rem;
    font-style: italic;
  }
.video-embed {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.25rem;
}
.video-embed .button {
    font-family: var(--font-stack-heading);
    font-size: 1.25rem;
  }
.video-embed--narrow .video-embed__video {
      border: 1px solid #000000;
      margin-left: auto;
      margin-right: auto;
      max-width: 46.875rem;
    }
.flex {
  flex-wrap: wrap;
  margin: 3rem 0;
}
/* until we can use Flex gap (https://caniuse.com/#search=gap) */
.flex > * {
    margin: calc(1.5rem / 2);
  }
.grid {
  display: grid;
  grid-gap: 3rem;
  margin: 3rem 0;
}
.grid.page-layout.grid--major-minor-minor {
      margin: 1.5rem 0;
      grid-gap: 3rem;
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s;
    }
.nest-set-2col {
  display: grid;
  grid-gap: 3rem;
  margin: 3rem auto;
  max-width: 100rem;
}
.nest-set-2col .component {
    padding: 0;
  }
.pageshell {
  margin: 0 auto;
  max-width: 90rem;
}
.pageshell__main {
    display: grid;
    grid-gap: 3rem;
    padding: 1.5rem 1.5rem 3rem 1.5rem;
  }
.pageshell__primary .wrapper {
      padding: 0;
    }
.pageshell__secondary, .pageshell__tertiary {
    align-self: baseline;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%);
    padding: 1.5rem;
  }
.pageshell--default .pageshell__main .wrapper {
        padding: 0;
      }
.pageshell--default .pageshell__primary {
      grid-row: 2;
    }
.pageshell--default .pageshell__secondary {
      grid-row: 1;
    }
.pageshell--default .pageshell__tertiary {
      grid-row: 3;
    }
.pageshell--fullwidth .pageshell__main {
      display: block;
      padding: 0;
    }
.pageshell--fullwidth .pageshell__primary .wrapper {
        padding: 0 1.5rem;
      }
.pageshell--narrow .pageshell__secondary {
      grid-row: 1;
    }
.pageshell--wide .pageshell__secondary {
      grid-row: 1;
    }
.pageshell--spanned .pageshell__secondary {
      grid-row: 1;
    }
.pageshell--spanned .pageshell__zeroth .component, .pageshell--spanned .pageshell__zeroth .wrapper {
        padding: 0;
      }
.sub-layout {
  display: grid;
  grid-gap: 1.5rem;
}
.default {
  /* Default */
}
.fullwidth {
  /* Fullwidth */
}
.sg-definition {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin: 0 2rem;
  padding: 2rem 0;
}
.sg-definition + .sg-definition {
    border-top: 1px solid #d8d8d8;
  }
.sg-definition__label {
    font-weight: bold;
  }
.sg-definition--body--grid > * {
        margin-top: 2rem;
      }
.sg-definition--heading {
    display: grid;
    grid-template-columns: 1fr;
  }
.sg-definition--heading .sg-h1-is-mobile {
      font-size: 2.125rem !important;
    }
.sg-definition--heading .sg-h2-is-mobile {
      font-size: 1.875rem !important;
    }
.sg-definition--heading .sg-h3-is-mobile {
      font-size: 1.5rem !important;
    }
.sg-definition--heading .sg-h4-is-mobile {
      font-size: 1.25rem !important;
    }
.sg-definition--heading .sg-h5-is-mobile {
      font-size: 1.125rem !important;
    }
.sg-definition--heading .sg-h6-is-mobile {
      font-size: 1rem !important;
    }
.sg-definition--palette {
    padding: 1.5rem;
  }
.sg-definition--palette .grid > * {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
.sg-definition--palette__card {
      background-color: transparent;
      border: 0;
      border-radius: 0;
      padding: 0;
      pointer-events: none;
      text-align: left;
    }
.sg-definition--palette__card .card {
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid var(--color-gray-4);
        border-radius: 0;
        box-shadow: none;
        margin-top: .5rem;
        padding: 0 0 66.666% 0;
      }
.sg-definition--palette__card .dl {
        font-size: .7rem;
      }
.sg-definition--palette__card .dl, .sg-definition--palette__card .dd, .sg-definition--palette__card .dt {
        color: rgba(51, 51, 51, 1);
      }
.sg-definition--palette__card .richtext, .sg-definition--palette__card .link {
        display: none;
      }
.sg-definition--palette__card:hover,.sg-definition--palette__card:active,.sg-definition--palette__card:focus {
        background-color: transparent;
      }
.sg-definition--palette__card:hover a,.sg-definition--palette__card:active a,.sg-definition--palette__card:focus a {
          text-decoration: none;
        }
.sg-definition--palette__card:hover .card,.sg-definition--palette__card:active .card,.sg-definition--palette__card:focus .card {
          background-color: unset;
        }
.sg-definition--palette__card .card .heading, .sg-definition--palette__card .card .link, .sg-definition--palette__card .card .richtext {
          display: none;
        }
.sg-definition--palette__card .figure .figcaption {
          padding: 1.5rem;
        }
.accordion--content .accordion__heading::before {
	content: '';
}
.accordion--content .accordion__heading::after {
	content: '';
}
.page-nav__heading .heading::before {
	content: '';
}
.page-nav__heading .heading::after {
	content: '';
}
@media only screen and (min-width: 24rem) {
.split-text__cta .link + .link {
        margin-left: 2ch
    }
      }
@media only screen and (min-width: 40rem) {
.card-deck__layout {
      grid-template-columns: 1fr 1fr
  }
.card-deck--split-media-card .card-deck__layout {
        grid-template-columns: 1fr
    }
.modal__dialog {
      left: calc(50% - 40vw);
      max-height: calc(100vh - 2rem);
      max-width: 80vw;
      top: 2rem
  }
.tabs--vertical, .tabs--vert {
      align-content: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start
  }
.tabs--vertical .tabs__tab-list, .tabs-vert .tabs__tab-list {
        flex-direction: column;
        justify-content: flex-start;
        max-width: 200px;
        width: 15%
    }

        .tabs--vertical .tabs__tab-list + .tabs__panel-list, .tabs-vert .tabs__tab-list + .tabs__panel-list {
          border-left: 1px solid #d8d8d8;
        }
.tabs--vertical .tabs__tab, .tabs-vert .tabs__tab {
        padding: 2rem 1rem
    }
.tabs--vertical .tabs__panel-list, .tabs-vert .tabs__panel-list {
        width: calc(100% - 200px)
    }

        .tabs--vertical .tabs__panel-list + .tabs__tab-list, .tabs-vert .tabs__panel-list + .tabs__tab-list {
          border-left: 1px solid #d8d8d8;
        }
    }
@media only screen and (min-width: 48rem) {
.page-intro__content {
      grid-column: 2/span 11;
      grid-row: 1
  }
.page-intro__icon {
      display: block;
      grid-column: 1;
      grid-row: 1
  }
.notification {
    padding: 1.5rem
}
.form-section__header {
      flex-direction: row;
      padding: 1.5rem 3rem
  }
.form-section__content {
      padding: 0 3rem
  }
.form-section__actions {
      margin-top: 0
  }
.read-only, .field-wrapper {
    grid-template-columns: 1fr 2fr
}
.grants__content {
      grid-template-columns: 1fr 1fr
  }
.grants__list {
      grid-template-columns: 1fr 1fr
  }
.progress-bar__labels {
    flex-direction: row
}
.MuiTabs-indicator {
    background-color: #ededed !important
}
.grants-display {
    padding: 1.5rem
}
.grants-display__layout {  
      grid-template-columns: 1fr 12rem
  }
.grants-display--scholarships .grants-display__layout {
        grid-template-columns: 2rem 3rem 3fr 1fr
    }
.button, .input[type="submit"] {
    font-size: .875rem;
    min-width: 10.9375rem;
    padding: .5rem 2.25rem
}
.flex .card {
      max-width: 30%
  }
.media-object {
    flex-direction: row
}
.media-object > :first-child {
      margin-right: 2rem
  }
.media-object__figure {
      margin-bottom: 0;
      order: 0
  }
.media-object--no-stack .media-object__figure {
        margin-top: 0
    }
.richtext table {
      display: table
  }
.richtext table thead {
        display: table-header-group
    }
.richtext table tbody {
        display: table-row-group
    }
.richtext table tfoot {
        display: table-row-group
    }
.richtext table tr {
        display: table-row
    }
.richtext table th, .richtext table td {
        display: table-cell
    }
.richtext table td::before {
          content: ''
      }
.richtext table td > * {
          text-align: left
      }
.wrapper--flush {
      padding: 0
  }
.wrapper--featured {
      padding: 2rem 0
  }
.accordion__head {
      display: flex;
      text-align: left
  }
.card-deck--horizontal, .card-deck--horizontal-3up {
      padding: 1.5rem 3rem;
      margin-bottom: 3rem
  }
.card-deck--horizontal .card-deck__layout, .card-deck--horizontal-3up .card-deck__layout {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 3rem
    }
.card-deck--horizontal .card-deck__layout::before, .card-deck--horizontal-3up .card-deck__layout::before {
          background-color: #1B4446;
          content: '';
          display: block;
          height: 60%;
          position: absolute;
          top: 20%;
          width: calc(100% + 6rem);
          z-index: -1
      }
.card-deck--horizontal-3up .card-deck__layout {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 3rem
    }
.card-deck--split-media-card {
      padding: 1.5rem 3rem;
      margin-bottom: 3rem
  }
.global-footer__layout {
      align-items: start;
      display: grid;
      grid-template-columns: repeat(12, 1fr)
  }
.global-footer__brand {
      grid-column: 1/span 6
  }
.global-footer__navigation {
      grid-column: 7/span 2
  }
.global-footer__connect {
      margin-top: 0;
      grid-column: 10/span 3
  }
.nav-item__label {
      height: 4.1255rem
  }
.logo__icon {
      max-height: 7.125rem;
      max-width: 14.5rem
  }
.logo.has-mobile {
      width: 100%
  }

      .logo.has-mobile .logo__icon {
        display: block;
      }

        .logo.has-mobile .logo__icon--mobile {
          display: none;
        }
.logo__inner-wrap {
      height: 5.625rem
  }
.marquee__layout {
      display: grid; 
      grid-template-columns: repeat(12, 1fr)
  }
.marquee__media {
      grid-column: 7/span 6;
      grid-row: 1
  }
.marquee__content {
      grid-column: 1/span 6;
      grid-row: 1;
      padding: 1.5rem 3rem 1.5rem 1.5rem
  }
.marquee__cta {
      margin: 3rem 3rem 1.5rem 0
  }
.media-text-wrap .figure {
      max-width: calc(50% - 1.5rem);
      margin: 0 0 1.5rem 1.5rem
  }
.modal--alternate .modal__dialog {
        padding: 3rem 4rem;
        top: 3rem
    }
.split-text__layout {
      grid-gap: 3rem;
      grid-template-columns: 1fr 2fr
  }
.split-text__heading {
      grid-column: 1
  }
.split-text__content {
      grid-column: 2;
      padding-right: 2rem
  }
.umbraco-form .turnstyle-grid--1up, .umbraco-form .turnstyle-grid--2up, .umbraco-form .turnstyle-grid--3up, .umbraco-form {
      grid-gap: 1.5rem 3rem
  }
.umbraco-form .turnstyle-grid--2up {
      grid-template-columns: 1fr 1fr
  }
.umbraco-form .turnstyle-grid--3up {
      grid-template-columns: 1fr 1fr 1fr
  }
.flex {
    display: flex;
    gap: 1.5rem
}
.flex--no-wrap {
      flex-wrap: nowrap
  }
.flex--justify-center {
      justify-content: center
  }
.flex--space-between {
      justify-content: space-between
  }
.flex--space-evenly {
      justify-content: space-evenly
  }
.grid--2col {
      grid-template-columns: 1fr 1fr
  }
.grid--3col {
      grid-template-columns: 1fr 1fr 1fr
  }
.grid--4col {
      grid-template-columns: 1fr 1fr 1fr 1fr
  }
.sg-definition {
    margin: 0 4rem;
    padding: 4.25rem 0
}
      .sg-definition--body > * {
        padding: 0 1rem;
      }
.sg-definition--body--grid > * {
          margin-top: 0
      }
.sg-definition--heading {
      grid-template-columns: 1fr 3fr 3fr
  }

      .sg-definition--heading-dynamic {
        grid-template-columns: 1fr 4fr 2fr;
      }
    }
@media only screen and (min-width: 56rem) {
body {
    padding-top: 0
}
.brand {
    height: 44px;
    width: 128px
}
.featured-split-media__image {
      justify-content: flex-end
  }
.featured-split-media__layout {
      grid-template-columns: 1fr 1fr
  }
.global-header {
    height: 7.125rem;
    position: relative;
    transition: none
}
.global-header__layout {
      display: grid;
      height: 7.125rem;
      grid-template-columns: repeat(12, 1fr)
  }
.global-header__brand {
      grid-column: 1/span 2;
      grid-row: 1;
      margin-top: 1rem
  }
.global-header__brand {
      grid-column: 1;
      grid-row: 1/span 3;
      margin-top: 0
  }
.global-header__backdrop {
      top: 0
  }
.sub-open .global-header__backdrop {
        background-color: rgba(0, 0, 0, .25)
    }
.global-header__navigation {
      height: 4.1255rem;
      grid-column: 3/span 10;
      grid-row: 2;
      margin-right: -.5rem
  }
.global-header__navigation > .nav-utility {
        transform: translateX(0)
    }
.global-header__navigation > .navigation {
        margin-right: 0
    }
.global-header__navigation > .navigation > .nav-item {
          opacity: 1
      }
.global-header__utility {
      align-items: center;
      display: flex;
      height: 3rem;
      justify-content: flex-end;
      grid-column: 3/span 10;
      grid-row: 1
  }
.global-header__mobile-search {
      display: none
  }
.nav-item {
    border-bottom: 4px solid transparent;
    height: 4.1255rem;
    padding: .5rem .5rem 0;
    position: relative;
    text-align: center;
    width: auto
}
.nav-item + .nav-item {
      margin-left: .75rem
  }
.nav-item.is-current-section {
      border-bottom: 4px solid #a2ba69
  }
.nav-item.is-current-section .nav-item__label {
      /*  color: var(--color-sup-3); */
    }
.nav-item.is-open .nav-item__menu {
        opacity: 1;
        right: -1.4rem
    }
.nav-item.has-menu:focus-within .nav-item__menu {
          opacity: 1;
          pointer-events: all;
          right: -1.4rem
      }
.nav-item--mobile-only {
      display: none;
      pointer-events: none
  }
.nav-item__menu-items .link {
        flex: 1 1 auto;
        padding: .75rem 1.5rem .75rem 1.5rem
    }
.nav-item__menu {
      border-radius: 5px;
      box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%);
      bottom: unset;
      height: auto;
      left: auto;
      max-width: 50vw;
      min-height: 0;
      opacity: 0;
      overflow: initial;
      padding: 1.5rem 0;
      pointer-events: none;
      position: absolute;
      right: -1.4rem;
      top: 4.1255rem;
      width: 320px
  }
.nav-item__menu::before {
        background-color: #ffffff;
        content: '';
        display: block;
        height: 24px;
        left: auto;
        position: absolute;
        right: 1.5rem;
        top: -8px;
        transform: rotate(45deg);
        width: 24px
    }
      .is-open .nav-item__link, .is-open .nav-item__label, .is-open .nav-item__toggle {
        border-color: #6e9e75;
        border-bottom: none;
      }
.nav-item__toggle:focus {
        /* placeholder state */
    }
.has-menu .nav-item__label {
        background-image: url(../images/icons/select-active-white.svg);
        background-position: center right;
        background-repeat: no-repeat;
        background-size: 12px 8px;
        padding-right: 1.25rem
    }
.has-menu .nav-item__label:hover,.has-menu .nav-item__label:active,.has-menu .nav-item__label:focus {
          background-image: url(../images/icons/select-hover.svg);
          color: #b7dff6
      }
.is-current-section.has-menu .nav-item__label {
        background-image: url(../images/icons/select-active-white.svg)
    }
.is-current-section.has-menu .nav-item__label:hover,.is-current-section.has-menu .nav-item__label:active,.is-current-section.has-menu .nav-item__label:focus {
          color: #b7dff6;
          background-image: url(../images/icons/select-hover.svg)
      }
.navigation__drawer {
      background-color: transparent;
      border: none;
      box-shadow: none;
      display: flex;
      flex-direction: row;
      height: 4.1255rem;
      justify-content: flex-end;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      overflow: unset;
      padding: 1.5rem 0;
      position: unset;
      top: 0;
      transform: none;
      transition: .2s cubic-bezier(.165, .84, .44, 1) 0s
  }
.navigation__toggle {
      display: none;
      opacity: 0;
      pointer-events: none;
      right: 0
  }
.navigation.sub-open .navigation__back {
        opacity: 0;
        pointer-events: none
    }
.navigation__back {
      display: none;
      opacity: 0;
      pointer-events: none;
      transition: none
  }
.page-nav__heading {
      border-bottom: 1px solid #ededed;
      padding-bottom: .75rem;
      pointer-events: none;
      margin-bottom: 1.5rem
  }
.page-nav__heading .heading {
        color: rgba(51, 51, 51, 1);
        padding-bottom: .75rem;
        pointer-events: none
    }

        .page-nav__heading .heading::before, .page-nav__heading .heading::after {
          display: none;
        }
.page-nav__menu {
      max-height: none
  }
.split-media__layout {
      align-items: center;
      display: grid;
      grid-gap: 3rem;
      grid-template-columns: 1fr 1fr
  }
.split-media--right .image {
        grid-column: 2;
        grid-row: 1
    }
.split-media--right .figcaption {
        grid-column: 1
    }
.grid.page-layout.grid--major-minor-minor {
        grid-template-columns: 20% 1fr 20%
    }
.nest-set-2col {
    grid-template-columns: 1fr 1fr
}
.pageshell__main {
      grid-template-columns: 20% 1fr 22%
  }
.pageshell__primary {
      grid-column: 1/span 3
  }
.pageshell__secondary {
      grid-column: 1
  }
.pageshell__tertiary {
      grid-column: 3;
      grid-row: 1
  }
.pageshell--default .pageshell__primary {
        grid-column: 2;
        grid-row: 1
    }
.pageshell--default .pageshell__secondary {
        grid-column: 1
    }
.pageshell--default .pageshell__tertiary {
        grid-column: 3;
        grid-row: 1
    }
.pageshell--fullwidth .pageshell__primary .wrapper {
          padding: 0 3rem
      }
.pageshell--narrow .pageshell__primary {
        grid-column: 2/span 2;
        grid-row: 1
    }
.pageshell--narrow .pageshell__secondary {
        grid-column: 1;
        grid-row: 1
    }
.pageshell--wide .pageshell__primary {
        grid-column: 2/span 2
    }
.pageshell--wide .pageshell__secondary {
        grid-column: 1;
        grid-row: 1
    }
.pageshell--spanned .pageshell__primary {
        grid-column: 2;
        grid-row: 2
    }
.pageshell--spanned .pageshell__secondary {
        grid-column: 1;
        grid-row: 1/span 2
    }
.pageshell--spanned .pageshell__tertiary {
        grid-column: 3;
        grid-row: 2
    }
.pageshell--spanned .pageshell__zeroth {
        grid-column: 2/span 2;
        grid-row: 1
    }
  }
@media only screen and (min-width: 64rem) {
.grants__content {
      grid-template-columns: 1fr 1fr 1fr 1fr
  }
.icon--brand {
      height: 56px;
      width: 160px
  }
.split-media-card::before {
      background-image: url(../images/icons/bookmark.svg);
      background-position: center top;
      background-repeat: no-repeat;
      background-color: transparent;
      content: '';
      height: 50px;
      left: 1rem;
      position: absolute;
      top: 0;
      width: 50px
  }
.split-media-card__layout {
      align-items: center;
      flex-direction: row;
      flex: 1 1 auto;
      justify-content: space-between;
      width: 100%
  }
.split-media-card__caption {
      padding: 3rem 1.5rem 3rem 5rem;
      margin: 0 3rem 0 0;
      order: 1
  }
.split-media-card__image {
      flex: 0 0 auto;
      order: 2;
      padding: 1.5rem
  }
.wrapper {
    padding: 0 3rem
}
.card-deck__layout {
      grid-gap: 3rem
  }
.card-deck--horizontal .card-deck__layout, .card-deck--horizontal-3up .card-deck__layout {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        max-width: none
    }
.card-deck--horizontal-3up .card-deck__layout {
        grid-template-columns: 1fr 1fr 1fr;
        max-width: none
    }
.card-deck--split-media-card .card-deck__layout {
        grid-gap: 3rem
    }
.global-footer {
    padding: 3rem
}
.global-footer__navigation {
      grid-column: 8/span 2
  }
.global-footer__connect {
      grid-column: 11/span 2
  }
.marquee__content {
      padding: 3rem
  }
.marquee__cta .link {
        margin: 1.5rem 1.5rem 0 0
    }
.overview {
    text-align: left
}
.pageshell--narrow .pageshell__primary {
        grid-column: 2
    }
    }
@media only screen and (min-width: 72rem) {
.sub-layout {
    grid-gap: 3rem;
    grid-template-columns: 1fr 1fr
}
  }
@media screen and (min-width: 1280px) {
	.heading--h1, .richtext h1 {
		font-size: 2.5rem;
	}
	.heading--h2, .richtext h2 {
		font-size: 2rem;
	}
	.heading--h3, .richtext h3 {
		font-size: 1.5rem;
	}
	.heading--h4, .richtext h4 {
		font-size: 1.25rem;
	}
	.heading--h5, .richtext h5 {
		font-size: 1.125rem;
	}
	.heading--h6, .richtext h6 {
		font-size: 1rem;
	}
	.heading--enormous {
		font-size: 12.5rem;
	}
}
@media only screen and (min-width: 90rem) {
.card {
    max-width: none
}
.grid.page-layout.grid--major-minor-minor {
        grid-template-columns: 250px 1fr 250px
    }
  }
@media only screen and (max-width: 48rem) {
    .sg-definition > * + * {
      margin-top: 2rem;
    }
  }
@media screen and (max-width: 420px) {
	.heading--h1, .richtext h1 {
		font-size: 2.125rem;
	}
	.heading--h2, .richtext h2 {
		font-size: 1.875rem;
	}
	.heading--h3, .richtext h3 {
		font-size: 1.5rem;
	}
	.heading--h4, .richtext h4 {
		font-size: 1.25rem;
	}
	.heading--h5, .richtext h5 {
		font-size: 1.125rem;
	}
	.heading--h6, .richtext h6 {
		font-size: 1rem;
	}
	.heading--enormous {
		font-size: 5rem;
	}
}
